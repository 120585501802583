import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureName, TenantState } from './tenant.reducer';
import { environment } from '../../../environments/environment';
import { SubscriptionType } from '../../subscriptions/models/subscription-type.model';

export const tenantStateSelector =
  createFeatureSelector<TenantState>(featureName);

export const tenantsSelector = createSelector(
  tenantStateSelector,
  state => state.tenants
);

export const currentTenantSelector = createSelector(
  tenantStateSelector,
  state => state.currentTenant
);

export const currentTenantDefaultBrandSelector = createSelector(
  currentTenantSelector,
  currentTenant => currentTenant?.defaultBrandCode
);

export const currentRoleSelector = createSelector(
  tenantStateSelector,
  state => state.currentRole
);

export const userHasBrandRoleSelector = createSelector(
  tenantStateSelector,
  state =>
    !!state.currentRole?.brandCode && state.currentRole?.brandCode?.length > 0
);

export const goToAppURLSelector = createSelector(
  currentTenantSelector,
  tenant => {
    const splitHref = environment.myUrl.split('.')[0].split('//');
    return environment.myUrl.replace(splitHref[1], tenant.defaultBrandCode);
  }
);

export const tenantCustomDomainAvailableSelector = createSelector(
  tenantStateSelector,
  state => state.customDomainAvailable
);

export const currentTenantDataBaseRegionSelector = createSelector(
  currentTenantSelector,
  tenant => tenant.region
);

export const currentTenantSubscriptionTypeSelector = createSelector(
  currentTenantSelector,
  tenant => tenant.subscriptionType
);

export const isCurrentTenantSubscriptionTypeUsersSelector = createSelector(
  currentTenantSubscriptionTypeSelector,
  subscriptionType => subscriptionType === SubscriptionType.users
);

export const isCurrentTenantSubscriptionTypeNotSideDrawerSelector =
  createSelector(
    currentTenantSubscriptionTypeSelector,
    subscriptionType => subscriptionType !== SubscriptionType.sideDrawers
  );
