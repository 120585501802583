import { ResolveFn } from '@angular/router';
import { TenantRole } from '../models/tenant-role.model';
import { inject } from '@angular/core';
import { TenantService } from '../../tenants/services/tenant.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TenantsRolesLoaded } from '../../auth/store/auth.actions';

export const tenantRolesResolver: ResolveFn<TenantRole[]> = (
  route,
  state,
  service = inject(TenantService),
  store: Store<AppState> = inject(Store<AppState>)
) => {
  return service.getTenantCustomRoles().pipe(
    catchError(() => of([])),
    tap({
      next: (tenantRoles: TenantRole[]) =>
        store.dispatch(new TenantsRolesLoaded({ tenantRoles })),
    })
  );
};
