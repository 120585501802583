import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sd-fab-extended',
  templateUrl: './sd-fab-extended.component.html',
  styleUrls: ['./sd-fab-extended.component.scss'],
})
export class SdFabExtendedComponent {
  @Input() primary: boolean;
  @Input() disabled: boolean;
  @Input() matIconString: string;
  @Input() tooltip: string;
  @Input() bgColor: string;
  @Input() dynamicText: boolean = true;
  @Input() position: 'left' | 'right' = 'left';
  @Output() buttonClicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    if (!this.disabled) {
      this.buttonClicked.emit(true);
    }
  }
}
