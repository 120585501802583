import { PlanItemFormType } from 'src/app/dictionary/models/plan-item-form-type.model';
import { PlanItemType } from 'src/app/plans/models/plan-item-type.model';
import { SpecificFieldType } from 'src/app/record-types/models/specific-field-type.model';

export class SmartFormItemFormType {
  public description?: string;
  public icon?: string;
  public instructions?: string;
  public instructionsb?: string;
  public title?: string;
  public nestable?: boolean;
  public type?: SpecificFieldType;
  public itemType: PlanItemType;
  public disabled?: boolean;

  constructor(
    {
      description,
      icon,
      instructions,
      instructionsb,
      title,
      nestable,
      type,
    }: PlanItemFormType,
    itemType: PlanItemType
  ) {
    this.description = description || undefined;
    this.icon = icon || undefined;
    this.instructions = instructions || undefined;
    this.instructionsb = instructionsb || undefined;
    this.title = title || undefined;
    this.nestable = nestable || undefined;
    this.type = (type as SpecificFieldType) || undefined;
    this.itemType = itemType;
    // WORKAROUND: disabled some options future features
    this.disabled =
      type === SpecificFieldType.table ||
      type === SpecificFieldType.surveyTable ||
      itemType === PlanItemType.record;
  }
}

export function adapterPlanItemFromTypeToFormBuilderItemFormType(
  planItemFormType: PlanItemFormType,
  itemType: PlanItemType
): SmartFormItemFormType {
  return new SmartFormItemFormType(planItemFormType, itemType);
}
