import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { sfAuthFeature } from './store/sf-auth.store';
import * as SfDictionaryEffects from './store/sf-dictionary.effects';
import * as SfCoreEffects from './store/sf-auth.effects';
import { SfWebMessagesService } from './services/sf-web-messages.service';
import * as dictionaryStore from '../../../dictionary/store/dictionary.reducer';
import { DictionaryService } from '../../../dictionary/services/dictionary.service';
import * as coreStore from '../../../core/store/core.reducer';
import { SdCacheModule } from '../../../shared/sd-cache/sd-cache.module';
import { DictionaryModule } from '../../../dictionary/dictionary.module';
import { PlansService } from '../../../plans/services/plans.service';
import { SubscriptionsService } from '../../../subscriptions/services/subscriptions.service';
import {
  bulkUploadReducer,
  featureName as bulkFeature,
} from '../../../bulk-upload/store/bulk-upload.reducer';
import { BulkUploadEffects } from '../../../bulk-upload/store/bulk-upload.effects';
import { AuthService } from '../../../auth/services/auth.service';
import { TenantService } from '../../../tenants/services/tenant.service';
import {
  featureName as tenantFeature,
  tenantReducer,
} from '../../../tenants/store/tenant.reducer';
import {
  brandReducer,
  featureName as brandFeature,
} from '../../../branding/store/brand.reducer';
import { BrandListEffects } from '../../../branding/store/brand-list.effects';
import { BrandSamplesEffects } from '../../../branding/store/brand-samples.effects';
import { BrandingService } from '../../../branding/services/branding.service';
import { SideDrawersService } from '../../../sidedrawers/services/side-drawers.service';
import { NetworksService } from '../../../networks/services/networks.service';
import {
  featureName as planFeature,
  planReducer,
} from '../../../plans/store/plan.reducer';
import { PlanEffects } from '../../../plans/store/plan.effects';
import { XlsxService } from 'src/app/shared/services/xlsx.service';
import { RecordTypesService } from 'src/app/record-types/services/record-types.service';
import { TemplatesService } from 'src/app/templates/services/templates.service';
import { BulkUploadService } from 'src/app/bulk-upload/services/bulk-upload.service';
import { FilesService } from 'src/app/files/services/files.service';
import { RecordsService } from 'src/app/records/services/records.service';
import { SdTablesModule } from 'src/app/shared/sd-tables/sd-tables.module';
import { TemplatesModule } from 'src/app/templates/templates.module';
import { AccountModule } from 'src/app/account/account.module';
import { AccountService } from 'src/app/account/services/account.service';
import {
  featureName as networkFeature,
  networkReducer,
} from 'src/app/networks/store/network.reducer';
import { NetworkEffects } from 'src/app/networks/store/network.effects';
import { DragDropOverlaySnackBarContainer } from '../../../files/directives/drag-and-drop/directive/drag-and-drop/drag-drop-overlay-container';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    StoreModule.forFeature(sfAuthFeature),
    StoreModule.forFeature(
      dictionaryStore.featureName,
      dictionaryStore.dictionaryReducer
    ),
    StoreModule.forFeature(tenantFeature, tenantReducer),
    StoreModule.forFeature(coreStore.featureName, coreStore.coreReducer),
    StoreModule.forFeature(bulkFeature, bulkUploadReducer),
    StoreModule.forFeature(brandFeature, brandReducer),
    StoreModule.forFeature(planFeature, planReducer),
    StoreModule.forFeature(networkFeature, networkReducer),
    EffectsModule.forFeature([
      SfDictionaryEffects,
      SfCoreEffects,
      BulkUploadEffects,
      BrandListEffects,
      BrandSamplesEffects,
      PlanEffects,
      NetworkEffects,
    ]),
    SdCacheModule,
    DictionaryModule,
    MatDialogModule,
    SdTablesModule,
    TemplatesModule,
    AccountModule,
  ],
  providers: [
    SfWebMessagesService,
    AuthService,
    AccountService,
    DictionaryService,
    PlansService,
    SubscriptionsService,
    TenantService,
    BrandingService,
    SideDrawersService,
    NetworksService,
    XlsxService,
    RecordTypesService,
    TemplatesService,
    BulkUploadService,
    FilesService,
    RecordsService,
    DragDropOverlaySnackBarContainer,
  ],
})
export class SfCoreModule {}
