import { DisplayValue } from 'src/app/record-types/models/display-value.model';
import { DicSidedrawerType } from '../../dictionary/models/sidedrawer-type.model';
import { SideDrawer } from '../../sidedrawers/models/side-drawer.model';

interface OrderIdInterface {
  orderId?: number;
}

export class UtilsHelper {
  static compareStrings(a: string, b: string): number {
    a = !!a && a.length > 0 ? a : '';
    b = !!b && b.length > 0 ? b : '';
    return a.localeCompare(b);
  }

  static apiVersion(apiUrl: string, version: number): string {
    return apiUrl.replace('v1', `v${version}`);
  }

  static compareStringsWithTagNumeric(a: string, b: string): number {
    // a = !!a && a.length > 0 ? a : '';
    // b = !!b && b.length > 0 ? b : '';
    return (a ?? '').localeCompare(b ?? '', undefined, {
      numeric: true,
    });
  }

  static compareNumbers(a: number, b: number): number {
    a = a ? a : 0;
    b = b ? b : 0;
    return a - b;
  }

  static hexToRgb(
    hex,
    alpha: number
  ): { r: number; g: number; b: number; a: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: alpha,
        }
      : null;
  }

  static getListFromEnum<T>(enumToTransform: T): Array<any> {
    const keys = Object.keys(enumToTransform);
    return keys.map(k => {
      return { key: k, value: enumToTransform[k] };
    });
  }

  static checkListWithOrderIdIsInvalid<T extends OrderIdInterface>(
    list: T[]
  ): boolean {
    return list.some(
      item => item?.orderId <= 0 || item?.orderId >= 1 || !item?.orderId
    );
  }

  static replaceOrderIdsOfListWithOrderIds<T extends OrderIdInterface>(
    list: T[]
  ): T[] {
    const data = [...list];
    try {
      data.sort((a, b) => UtilsHelper.compareNumbers(a.orderId, b.orderId));
    } catch (e) {
      console.warn(e);
    }
    return data.map((item, index) => {
      return {
        ...item,
        orderId: (index + 1) / (list.length + 1),
      };
    });
  }

  static mapToArray<T>(map: Map<string | number, T>): T[] {
    return Array.from(map, ([, value]) => ({ ...value }));
  }

  static formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static getSdType(dicSdTypes: DicSidedrawerType[], sd: SideDrawer): string {
    return sd.type === 'other'
      ? sd.typeOtherName
      : dicSdTypes.some(dicSdType => dicSdType.sdtype_enumid === sd.type)
      ? dicSdTypes.find(dicSdType => dicSdType.sdtype_enumid === sd.type)
          .sdtype_label
      : sd.type;
  }

  static sortListByPropertyName<T>(arrayToSort: T[], property: string): T[] {
    return arrayToSort.sort((a, b) => (a[property] > b[property] && 1) || -1);
  }

  static changeVersionApiUrl(url: string, version: number): string {
    return url.replace('v1', `v${version}`);
  }

  static reduceBooleanMapToBoolean(
    map: Map<string | number, boolean>
  ): boolean {
    const aux = [...map.values()];
    if (aux.length === 0) {
      return false;
    }
    return aux.reduce((p, c) => p || c);
  }

  static arrayToMap<T>(key: string, array: T[]): Map<string | number, T> {
    const map = new Map<string | number, T>();
    array.forEach(element => map.set(element[key], element));
    return map;
  }

  static getNewKeyFromMap<T>(map: Map<number, T>): number {
    return ([...map.keys()][map.size - 1] ?? 0) + 1;
  }

  static removesLinkTypeAndProtocol(
    link: string,
    find = /http:\/\/|tel:|mailto:|fax:/gi,
    replace = ''
  ): string {
    return link.replace(new RegExp(find, 'g'), replace);
  }

  static isValidContactLinkType(title: string): boolean {
    const invalidLinkTypes = [
      'Email',
      'Phone',
      'Fax',
      'iPhone',
      'Mobile',
      'Toll Free',
    ];
    return invalidLinkTypes.includes(title);
  }

  static convertToTitleCase(string: string): string {
    if (!string) {
      return '';
    }
    return string.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }

  static getDisplayValueFromLocaleId(
    displayValue: DisplayValue[],
    localeId: string
  ): string {
    return displayValue?.find(value => value.locale === localeId)?.value ?? '';
  }

  static removeElementsWithDuplicatedKeyFromArray<T>(
    key: string,
    array: T[]
  ): T[] {
    return UtilsHelper.mapToArray(UtilsHelper.arrayToMap<T>(key, array));
  }

  public static generateRandomId(): string {
    const ranDomId = [];
    const randomArray = window.crypto.getRandomValues(new Uint32Array(3));
    randomArray.forEach(randomNumber => {
      ranDomId.push(randomNumber.toString(16));
    });
    return ranDomId.join('-');
  }

  public static emailValidator(email: string): boolean {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }
}
