<mat-form-field>
  <input
    type="number"
    [formControl]="controller"
    [placeholder]="placeholder"
    matInput />
  <div class="suffix-container" matSuffix>
    <app-sd-tooltip-button
      *ngIf="!!tooltip && tooltip.length > 0"
      [tooltip]="tooltip"></app-sd-tooltip-button>
  </div>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
</mat-form-field>
