import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdTextEditorComponent } from './components/sd-text-editor/sd-text-editor.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [SdTextEditorComponent],
  imports: [CommonModule, ReactiveFormsModule, CKEditorModule],
  exports: [SdTextEditorComponent],
})
export class SdTextEditorModule {}
