import { Locale } from '../../dictionary/models/locale.model';

export class DisplayValue {
  constructor(
    public locale?: string,
    public value?: string,
    public description?: string
  ) {}

  static normalizeDisplayValue(
    values: DisplayValue[],
    locales: Locale[]
  ): DisplayValue[] {
    if (!values) {
      return [];
    }
    if (!locales) {
      return [];
    }
    return locales.map(locale => {
      const value =
        values?.find(
          dv => dv.locale?.toLowerCase() === locale.localeid?.toLowerCase()
        )?.value || values.at(0)?.value;
      return {
        locale: Locale.getLocaleId(locale),
        value,
      };
    });
  }
}
