import userflow from 'userflow.js';
import { environment } from '../../../environments/environment';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, map, tap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { authHeadersSelector } from '../../auth/store/auth.selectors';
import { HttpClient } from '@angular/common/http';
import { Account } from 'src/app/account/models/account.model';
import {
  AccountActionsTypes,
  AccountLoaded,
} from '../../account/store/account.actions';

type UserFlowState = {
  error?: unknown;
};

export const UserFlowStore = signalStore(
  withState<UserFlowState>({ error: null }),
  withMethods(
    (
      store,
      globalStore = inject(Store<AppState>),
      http = inject(HttpClient)
    ) => ({
      initUserFlow(): void {
        try {
          userflow?.init(environment.userFlowToken);
        } catch (error) {
          patchState(store, { error });
        }
      },
      async identifyUser(account: Account): Promise<void> {
        const email = account.emails?.[0]?.address ?? account.username;
        const signature = await firstValueFrom(
          http
            .post<{ hash: string }>(
              environment.userFlowHashUrl,
              { email },
              {
                headers: globalStore.selectSignal(authHeadersSelector)(),
              }
            )
            .pipe(
              catchError(_ => of({ hash: null })),
              map(({ hash }) => hash)
            )
        );
        if (signature) {
          await userflow
            ?.identify(
              email,
              {
                name: `${account.firstName} ${account.lastName}`,
                email,
                signed_up_at: account?.createdAt,
              },
              { signature }
            )
            .catch(error => patchState(store, { error }));
          return;
        }
        await userflow
          ?.identify(email, {
            name: `${account.firstName} ${account.lastName}`,
            email,
            signed_up_at: account?.createdAt,
          })
          .catch(error => patchState(store, { error }));
      },
    })
  ),
  withHooks({
    onInit(store, action$ = inject(Actions)) {
      action$
        .pipe(
          takeUntilDestroyed(),
          ofType<AccountLoaded>(AccountActionsTypes.AccountLoaded),
          tap(action => store.identifyUser(action.payload.data))
        )
        .subscribe();
    },
  })
);
