import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-sd-text-editor',
  templateUrl: './sd-text-editor.component.html',
  styleUrls: ['./sd-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdTextEditorComponent {
  @Input() height = 120;
  @Input() control: UntypedFormControl;
  editor = DecoupledEditor;
  config: unknown;

  @Input() set placeholder(placeholder: string) {
    this.config = {
      placeholder,
      toolbar: [
        'bold',
        'italic',
        'link',
        'blockQuote',
        '|',
        'alignment',
        'heading',
        '|',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'mediaEmbed',
        '|',
        'undo',
        'redo',
      ],
    };
  }

  onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }
}
