import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sd-fab-side',
  templateUrl: './sd-fab-side.component.html',
  styleUrls: ['./sd-fab-side.component.scss'],
})
export class SdFabSideComponent {
  @Input() primary: boolean;
  @Input() disabled: boolean;
  @Input() icon: string;
  @Input() iconAlt: string;
  @Input() tooltip: string;
  @Input() bgColor: string;
  @Input() position: 'left' | 'right' = 'left';
  @Output() buttonClicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    if (!this.disabled) {
      this.buttonClicked.emit(true);
    }
  }
}
