<label class="main-label" *ngIf="placeholder">{{ placeholder }}</label>
<mat-radio-group
  [formControl]="controller"
  [ariaLabel]="placeholder"
  [ngStyle]="{
    'grid-template-columns': 'repeat(' + columns + ', 1fr)'
  }"
  class="sd-radio-group">
  <div *ngFor="let option of options" class="sd-radio-button">
    <ng-container *ngIf="isVisible(option)">
      <mat-radio-button
        (change)="onSelect($event.value)"
        *ngIf="option.title"
        [checked]="verifyCheck(option.key)"
        [value]="option.key"
        class="radio-button">
      </mat-radio-button>
      <div
        *ngIf="option.title"
        [ngClass]="inlineDescription ? 'inline' : null"
        class="label">
        <div
          (click)="onSelect(option.key)"
          *ngIf="!!option.title"
          class="title">
          {{ option.title }}
        </div>
        <div
          (click)="onSelect(option.key)"
          *ngIf="!!option.description"
          class="description">
          ({{ option.description }})
        </div>
      </div>
    </ng-container>
  </div>
</mat-radio-group>
