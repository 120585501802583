import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

@Component({
  selector: 'app-sd-input',
  templateUrl: './sd-input.component.html',
  styleUrls: ['./sd-input.component.scss'],
})
export class SdInputComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() error: string;
  @Input() controller: UntypedFormControl;
  @Input() placeholder: string;
  @Input() suffixIsImage = false;
  @Input() prefixText: string;
  @Input() suffixText: string;
  @Input() suffix: string;
  @Input() suffixIsClickable: boolean;
  @Input() suffixIconHasPrimaryColor: boolean;
  @Input() focused: boolean;
  @Input() mask: string;
  @Input() tooltip: string;
  @Input() readonly = false;
  @Input() hideRequiredMarker? = true;
  @Output() suffixClicked = new EventEmitter<boolean>();
  displaySuffix = false;
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
  destroyRef = inject(DestroyRef);
  changeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.displaySuffix = !!this.suffix && this.suffix.length > 0;
    if (this.focused) {
      this.inputElement.nativeElement.focus();
    }
    this.controller.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.changeDetectorRef.markForCheck())
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.focused && changes.focused.currentValue) {
      this.inputElement.nativeElement.focus();
    }
  }

  onSuffix(): void {
    if (this.suffixIsClickable) {
      this.suffixClicked.emit(true);
    }
  }

  updateMatInputState() {
    if (this.inputElement) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
        this.inputElement.nativeElement.blur();
      }, 250);
    }
  }
}
