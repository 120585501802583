<mat-form-field [ngClass]="{ readOnly: readOnly }">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    [formControl]="controller"
    [ngClass]="{ 'without-this': withOutThis, readOnly: readOnly }">
    <mat-option
      (onSelectionChange)="selection.emit(option.key)"
      *ngFor="let option of options"
      [disabled]="option.disabled"
      [value]="option.key">
      <app-sd-selector-option
        [displaySvg]="displaySvg"
        [option]="option"
        [roundedAvatar]="roundedAvatar">
      </app-sd-selector-option>
    </mat-option>
  </mat-select>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
  <ng-content></ng-content>
  <div class="suffix-container" matSuffix>
    <app-sd-tooltip-button
      *ngIf="tooltip"
      [tooltip]="tooltip"></app-sd-tooltip-button>
  </div>
</mat-form-field>
