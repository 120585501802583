import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';
import { updateValueDisplayValueHelper } from 'src/app/core/helpers/display-value.helper';
import { getHardcodedMultiLanguageValue } from 'src/app/core/helpers/form-builder.helper';
import { localesIdsSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { DisplayValue } from 'src/app/record-types/models/display-value.model';
import { AppState } from 'src/app/reducers';
import { SdInputComponent } from '../sd-input/sd-input.component';

@Component({
  selector: 'app-sd-input-new-display-value',
  templateUrl: './sd-input-new-display-value.component.html',
  styleUrls: ['./sd-input-new-display-value.component.scss'],
})
export class SdInputNewDisplayValueComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() elementId: string; // WORKAROUND: use to force rerender when controller changes
  @Input() controller: FormControl<DisplayValue[]>;
  @Input() displayValue: DisplayValue[]; // initial value used in place of controller.value
  @Input() localeSelected: string; // current working locale selected
  @Input() hideLocaleOnPlaceHolder: boolean = false; // show (en-CA) in the placeholder
  @Input() placeholder: string;
  @Input() tooltip: string;
  @Input() error: string;
  @Input() type: string = 'text';
  @Input() mask: string;
  @Input() readonly: boolean = false;
  @Input() suffixIsImage = false;
  @Input() prefixText: string;
  @Input() suffixText: string;
  @Input() suffix: string;
  @Input() suffixIsClickable: boolean;
  @Input() suffixIconHasPrimaryColor: boolean;
  @Input() focused: boolean;
  @Input() hideRequiredMarker? = true;
  // enrich text inputs (app-sd-text-editor)
  @Input() isEnrichText: boolean = false;
  @Input() height: number = 120;

  @Output() suffixClicked = new EventEmitter<boolean>();
  @Output() onDisplayValueChange = new EventEmitter<DisplayValue[]>();

  @ViewChild(SdInputComponent) childInput!: SdInputComponent;

  internalInputController: FormControl<string>;
  subscription = new Subscription();

  private readonly store = inject(Store<AppState>);

  localesIds = this.store.selectSignal(localesIdsSelector)();

  ngOnInit(): void {
    if (this.displayValue && this.displayValue.length > 0) {
      this.controller.setValue(this.displayValue);
    }
    if (!this.controller.value || this.controller.value.length === 0) {
      this.controller.setValue(
        getHardcodedMultiLanguageValue(this.localesIds, '')
      );
    }

    this.internalInputController = new FormControl(
      this.currentDisplayValue.value,
      this.controller.validator,
      this.controller.asyncValidator
    );

    const internalInputChange$ = this.internalInputController.valueChanges.pipe(
      tap(() => {
        this.handleModelChange(this.internalInputController.value);
      })
    );
    this.subscription.add(internalInputChange$.subscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.internalInputController &&
      ((changes.localeSelected &&
        changes.localeSelected.previousValue !==
          changes.localeSelected.currentValue) ||
        (changes.elementId &&
          changes.elementId.previousValue !== changes.elementId.currentValue))
    ) {
      this.setValue();
    }
  }

  setValue() {
    let newValue = this.controller.value;
    if(!newValue || newValue.length === 0) {
      this.controller.setValue(getHardcodedMultiLanguageValue(this.localesIds, ''));
    }
    this.internalInputController.setValue(this.currentDisplayValue.value);
    this.childInput.updateMatInputState();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get currentDisplayValue() {
    return (
      this.controller?.value?.find(
        dispValue => dispValue.locale === this.localeSelected
      ) || {}
    );
  }

  getPlaceholder(): string {
    let placeholder = this.placeholder;
    if (!this.hideLocaleOnPlaceHolder) {
      placeholder += ` (${this.localeSelected})`;
    }
    if (this.controller.hasValidator(Validators.required)) {
      placeholder += ` *`;
    }
    return placeholder;
  }

  // Method to update current value
  handleModelChange(newValue: string) {
    const updatedDisplayValues = updateValueDisplayValueHelper(
      newValue,
      this.localeSelected,
      this.controller.value
    );

    this.controller.setValue(updatedDisplayValues);
    this.onDisplayValueChange.emit(updatedDisplayValues);
  }

  handleSuffixClicked(isClicked: boolean) {
    this.suffixClicked.emit(isClicked);
  }
}
