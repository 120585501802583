import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-sd-input-number',
  templateUrl: './sd-input-number.component.html',
  styleUrls: ['./sd-input-number.component.scss'],
})
export class SdInputNumberComponent {
  @Input() error: string;
  @Input() controller: UntypedFormControl;
  @Input() placeholder: string;
  @Input() tooltip: string;

}
