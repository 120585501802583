import {
  FormControl,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { DateHelper } from '../../../core/helpers/date.helper';
import { Option } from './option.model';

export class SdValidators {
  constructor() {}

  static emailValidator(control: UntypedFormControl): { [s: string]: boolean } {
    if (control.value === '') {
      return null;
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        control.value
      )
    ) {
      return null;
    } else {
      return { emailFormat: true };
    }
  }

  static emailOptionValidator(
    control: FormControl<string | Option>
  ): ValidationErrors {
    if (!control.value) {
      return null;
    }
    const email = (control.value as Option)?.key
      ? (control.value as Option).value
      : (control.value as string);
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return null;
    }
    return { emailFormat: true };
  }

  static emptyCharacters(control: UntypedFormControl): {
    [s: string]: boolean;
  } {
    if (
      !!control.value &&
      control.value !== '' &&
      control.value.trim().length === 0
    ) {
      return { emptyCharacters: true };
    } else {
      return null;
    }
  }

  static checkSpecialCharactersValidator(
    control: UntypedFormControl
  ): ValidationErrors {
    return SdValidators.checkSpecialCharactersAllowingDash(control.value)
      ? { specialCharacters: true }
      : null;
  }

  static checkSpecialCharacters(value: string): boolean {
    return /\W|_/.test(value);
  }

  static checkSpecialCharactersAllowingDash(value: string): boolean {
    return !/^[a-z0-9-]+$/.test(value);
  }

  static checkNumbers(value: string): boolean {
    return /[0-9]/.test(value);
  }

  static checkUpperCase(value: string): boolean {
    return /[A-Z]/.test(value);
  }

  static checkLowerCase(value: string): boolean {
    return /[a-z]/.test(value);
  }

  static calculateStrength(value: string): number {
    let strength = 0;
    if (SdValidators.checkLowerCase(value)) {
      strength++;
    }
    if (SdValidators.checkUpperCase(value)) {
      strength++;
    }
    if (SdValidators.checkNumbers(value)) {
      strength++;
    }
    if (SdValidators.checkSpecialCharacters(value)) {
      strength++;
    }
    return strength;
  }

  static passwordStrengthValidator(
    control: UntypedFormControl
  ): ValidationErrors {
    return SdValidators.calculateStrength(control.value) < 3
      ? { strength: true }
      : null;
  }

  static checkFileSize(file: File, maxMB: number): boolean {
    return file.size / 1048576 > maxMB;
  }

  static expirationDateValidator(
    control: UntypedFormControl
  ): ValidationErrors {
    const expiration = `${control.value}`;
    const month = parseInt(expiration.slice(0, 2), 10);
    const year = parseInt(expiration.slice(2, 4), 10) + 2000;
    const currentYear = new Date().getFullYear();
    if (month <= 0 || month > 12) {
      return { invalidDate: true };
    }
    if (year - currentYear > 10) {
      return { invalidDate: true };
    }
    const expirationDate = new Date(year, month - 1);
    return DateHelper.compareDates(expirationDate, new Date()) > 0
      ? null
      : { invalidDate: true };
  }

  static notNullOrUndefinedValidator(
    control: UntypedFormControl
  ): ValidationErrors {
    return control.value === undefined || control.value === null
      ? { required: true }
      : null;
  }
}
