import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdInputComponent } from './components/sd-input/sd-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SdSelectorComponent } from './components/sd-selector/sd-selector.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { SdCheckboxComponent } from './components/sd-checkbox/sd-checkbox.component';
import { SdRadioGroupComponent } from './components/sd-radio-group/sd-radio-group.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SdDatePickerComponent } from './components/sd-date-picker/sd-date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SdSearchComponent } from './components/sd-search/sd-search.component';
import { SdTextAreaComponent } from './components/sd-text-area/sd-text-area.component';
import { SdButtonsModule } from '../sd-buttons/sd-buttons.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SdAutocompleteComponent } from './components/sd-autocomplete/sd-autocomplete.component';
import { SdPasswordComponent } from './components/sd-password/sd-password.component';
import { SdRadioGroupCustomValueInputComponent } from './components/sd-radio-group/components/sd-radio-group-custom-value-input/sd-radio-group-custom-value-input.component';
import { SdSelectorOptionComponent } from './components/sd-selector-option/sd-selector-option.component';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { SdLocaleSelectorComponent } from './components/sd-locale-selector/sd-locale-selector.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { CountryExtensionSelectorComponent } from './components/country-extension-selector/country-extension-selector.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from 'ngx-mask';
import { SdCheckboxWithControllerComponent } from './components/sd-checkbox-with-controller/sd-checkbox-with-controller.component';
import { SdInputNumberComponent } from './components/sd-input-number/sd-input-numbercomponent';
import { SdSignaturePreviewComponent } from './components/sd-signature-preview/sd-signature-preview.component';
import { SdRadioGroupWithControllerComponent } from './components/sd-radio-group-with-controller/sd-radio-group-with-controller.component';
import { SdInputNewDisplayValueComponent } from './components/sd-input-new-display-value/sd-input-new-display-value.component';
import { SdTextEditorModule } from '../sd-text-editor/sd-text-editor.module';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    SdInputComponent,
    SdSelectorComponent,
    SdCheckboxComponent,
    SdCheckboxWithControllerComponent,
    SdInputNumberComponent,
    SdRadioGroupComponent,
    SdRadioGroupWithControllerComponent,
    SdDatePickerComponent,
    SdSearchComponent,
    SdTextAreaComponent,
    SdAutocompleteComponent,
    SdPasswordComponent,
    SdRadioGroupCustomValueInputComponent,
    SdSelectorOptionComponent,
    CountrySelectorComponent,
    SdLocaleSelectorComponent,
    PhoneNumberInputComponent,
    CountryExtensionSelectorComponent,
    PasswordStrengthComponent,
    SdInputNewDisplayValueComponent,
    SdSignaturePreviewComponent,
  ],
  exports: [
    SdInputComponent,
    SdSelectorComponent,
    SdCheckboxComponent,
    SdCheckboxWithControllerComponent,
    SdInputNumberComponent,
    SdRadioGroupComponent,
    SdRadioGroupWithControllerComponent,
    SdDatePickerComponent,
    SdSearchComponent,
    SdTextAreaComponent,
    SdAutocompleteComponent,
    SdPasswordComponent,
    SdSelectorOptionComponent,
    CountrySelectorComponent,
    SdLocaleSelectorComponent,
    PhoneNumberInputComponent,
    CountryExtensionSelectorComponent,
    SdInputNewDisplayValueComponent,
    SdSignaturePreviewComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SdButtonsModule,
    MatProgressSpinnerModule,
    SdTextEditorModule,
  ],
  providers: [provideNgxMask()],
})
export class SdFormsModule {}
