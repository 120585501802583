<button
  (click)="onClick()"
  [disabled]="disabled"
  [matTooltip]="!!tooltip ? tooltip : ''"
  [ngClass]="primary ? 'primary' : 'secondary'"
  [class]="'sd-fab-side sd-fab-side-' + position"
  [ngStyle]="{ 'background-color': bgColor }"
  mat-fab
  extended
  #matTooltip="matTooltip"
  (mouseleave)="!!tooltip ? matTooltip.hide() : ''">
  <div
    *ngIf="!!icon"
    [ngClass]="primary ? 'icon  icon-primary' : 'icon icon-secondary'"
    [ngStyle]="{
      '-webkit-mask': 'url(' + icon + ') no-repeat 50% 50%',
      mask: 'url(' + icon + ') no-repeat 50% 50%'
    }"></div>
  <span class="text">
    <ng-content></ng-content>
  </span>
</button>
