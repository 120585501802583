import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { tap } from 'rxjs';

@Component({
  selector: 'app-sd-checkbox-with-controller',
  templateUrl: './sd-checkbox-with-controller.component.html',
  styleUrls: ['./sd-checkbox-with-controller.component.scss'],
})
export class SdCheckboxWithControllerComponent {
  @Input() value = false;
  @Input() disabled = false;
  @Input() alignItemsCenter:
    | 'auto'
    | 'normal'
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'baseline'
    | 'first baseline'
    | 'last baseline' = 'center';
  @Input() controller: UntypedFormControl;
}
