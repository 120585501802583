<button
  (click)="onClick()"
  [disabled]="disabled"
  [matTooltip]="!!tooltip ? tooltip : ''"
  [ngClass]="primary ? 'primary' : 'secondary'"
  [class]="'sd-fab-extended'"
  [ngStyle]="{ 'background-color': bgColor }"
  mat-mini-fab
  #matTooltip="matTooltip"
  (mouseleave)="!!tooltip ? matTooltip.hide() : ''">
  <div class="button-content">
    <mat-icon *ngIf="matIconString">{{ matIconString }}</mat-icon>
    <span class="text" [ngClass]="dynamicText ? 'text-dynamic' : ''">
      <ng-content></ng-content>
    </span>
  </div>
</button>
