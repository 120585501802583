export enum SpecificFieldType {
  integer = 'integer',
  number = 'number',
  address = 'address',
  email = 'email',
  url = 'url',
  rtf = 'rtf',
  money = 'money',
  percentage = 'percentage',
  date = 'date',
  enum = 'enum',
  string = 'string',
  password = 'password',
  table = 'table',
  optiongroup = 'optiongroup',
  radiogroup = 'radiogroup',
  boolean = 'boolean',
  enumNestable = 'DropDownItemTypeForTable', // TODO Then when the enum value is "nestable" analyze remove this value, but take into account the values ​​persisted in the database and change where appropriate in the code.
  surveyTable = 'surveytable',
  signature = 'signature',
}
