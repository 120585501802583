<app-sd-input
  *ngIf="!isEnrichText"
  [controller]="internalInputController"
  [placeholder]="getPlaceholder()"
  [tooltip]="tooltip"
  [mask]="mask"
  [readonly]="readonly"
  [type]="type"
  [error]="error"
  [suffixIsImage]="suffixIsImage"
  [prefixText]="prefixText"
  [suffixText]="suffixText"
  [suffix]="suffix"
  [suffixIsClickable]="suffixIsClickable"
  [suffixIconHasPrimaryColor]="suffixIconHasPrimaryColor"
  [focused]="focused"
  [hideRequiredMarker]="hideRequiredMarker"
  (suffixClicked)="handleSuffixClicked($event)" />
<app-sd-text-editor
  *ngIf="isEnrichText"
  [control]="internalInputController"
  [height]="height"
  [placeholder]="getPlaceholder()" />
