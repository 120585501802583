import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/services/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient } from '@angular/common/http';
import { MyOtherSideDrawer } from '../models/my-other-side-drawer.model';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import {
  compareSideDrawerRoles,
  SidedrawerRoles,
} from '../../core/roles/sidedrawer.roles';
import {
  SidedrawerNetworkLoaded,
  SidedrawersNetworkLoaded,
} from '../store/sidedrawers-network.actions';
import { Network } from '../models/network.model';
import { MyNetwork } from '../models/my-network.model';
import { sidedrawersNetworksBySideDrawerIdSelector } from '../store/sidedrawers-network.selectors';
import {
  currentRoleSelector,
  currentTenantSelector,
  userHasBrandRoleSelector,
} from '../../tenants/store/tenant.selectors';
import { accountSelector } from '../../account/store/account.selector';
import { CreateSideDrawerNetworkWithOpenIdDto } from '../models/create-side-drawer-network-with-open-id.dto';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { TransferOwnershipDto } from '../models/transfer-ownership.dto';
import { Relation } from '../models/relation.model';
import { GetNetworksResponseDto } from '../models/get-networks-response.dto';
import { Contributor } from '../models/contributor.model';
import { PaginatorApiResponse } from '../../sidedrawers/models/paginator-api-response.model';
import { NetworkV2Dto } from '../models/network.v2.dto';

@Injectable()
export class NetworksService {
  private tenantApi = environment.tenantApi;
  private networksApi = environment.networksApi;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store<AppState>,
    private readonly http: HttpClient
  ) {}

  getCollaboratorsNetworks(
    sidedrawerId: string,
    recordId?: string
  ): Observable<GetNetworksResponseDto[]> {
    const params = recordId ? { sidedrawerId, recordId } : { sidedrawerId };
    return this.http.get<GetNetworksResponseDto[]>(
      this.networksApi + 'network/collaborators',
      {
        headers: this.authService.getHeaders(),
        params,
      }
    );
  }

  getInvitationsNetworks(
    sidedrawerId: string,
    recordId?: string
  ): Observable<GetNetworksResponseDto[]> {
    const params = recordId ? { sidedrawerId, recordId } : { sidedrawerId };
    return this.http.get<GetNetworksResponseDto[]>(
      this.networksApi + 'network/invitations',
      {
        headers: this.authService.getHeaders(),
        params,
      }
    );
  }

  createSideDrawerNetworkWithNetworksApi(
    sidedrawerId: string,
    body: Network
  ): Observable<{ id?: string; _id?: string }> {
    if (!body.relation) {
      body.relation = Relation.getDefaultRelation();
    }
    return this.http.post<{ id?: string; _id?: string }>(
      this.networksApi + `sidedrawer/sidedrawer-id/${sidedrawerId}/network`,
      body,
      { headers: this.authService.getHeaders() }
    );
  }

  updateSideDrawerNetworkWithNetworksApi(
    sidedrawerId: string,
    networkId: string,
    body: Network
  ): Observable<{ id?: string; _id?: string }> {
    if (!body.relation) {
      body.relation = Relation.getDefaultRelation();
    }
    return this.http.put<{ id?: string; _id?: string }>(
      this.networksApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/network/network-id/${networkId}`,
      body,
      { headers: this.authService.getHeaders() }
    );
  }

  createSideDrawerNetworkWithOpenId(
    sidedrawerId: string,
    body: CreateSideDrawerNetworkWithOpenIdDto
  ): Observable<{ id?: string; _id?: string }> {
    if (!body.relation) {
      body.relation = Relation.getDefaultRelation();
    }
    return this.http.post<{ id?: string; _id?: string }>(
      UtilsHelper.changeVersionApiUrl(this.networksApi, 2) +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/network`,
      body,
      { headers: this.authService.getHeaders() }
    );
  }

  createSideDrawerNetworkV2FromTenantApi(
    tenantId: string,
    sideDrawerId: string,
    sidedrawerRole: string,
    key:
      | { openId: string }
      | { invitationCode: string }
      | { teamId: string }
      | { federationId: string },
    relation: Relation,
    region: string
  ): Observable<{ id?: string; _id?: string }> {
    return this.http.post<{ id?: string; _id?: string }>(
      UtilsHelper.changeVersionApiUrl(this.tenantApi, 2) +
        `tenant/tenant-id/${tenantId}/sidedrawers/sidedrawer-id/${sideDrawerId}/network?region=${region}`,
      {
        ...key,
        sidedrawerRole,
        relation: relation ?? Relation.getDefaultRelation(),
      },
      { headers: this.authService.getHeaders() }
    );
  }

  getSideDrawerNetworkV2FromTenantApi(
    tenantId: string,
    sideDrawerId: string
  ): Observable<PaginatorApiResponse<NetworkV2Dto>> {
    return this.http.get<PaginatorApiResponse<NetworkV2Dto>>(
      UtilsHelper.changeVersionApiUrl(this.tenantApi, 2) +
        `tenant/tenant-id/${tenantId}/sidedrawers/sidedrawer-id/${sideDrawerId}/network`,
      { headers: this.authService.getHeaders() }
    );
  }

  deleteSideDrawerNetwork(
    tenantId: string,
    sidedrawerId: string,
    networkId: string
  ): Observable<boolean> {
    return this.http
      .delete(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/sidedrawers/sidedrawer-id/${sidedrawerId}/network/network-id/${networkId}`,
        { headers: this.authService.getHeaders() }
      )
      .pipe(map(() => true));
  }

  getSideDrawerNetworks(sidedrawerId: string): Observable<MyNetwork[]> {
    return this.store.pipe(
      select(sidedrawersNetworksBySideDrawerIdSelector({ sidedrawerId })),
      take(1),
      mergeMap(networks =>
        networks?.length > 0
          ? of(networks)
          : this.http.get<MyNetwork[]>(
              this.networksApi + `network?sidedrawerId=${sidedrawerId}`,
              { headers: this.authService.getHeaders() }
            )
      )
    );
  }

  transferSideDrawerOwnership(
    sidedrawerId: string,
    dto: TransferOwnershipDto
  ): Observable<boolean> {
    if (!dto.relation) {
      dto.relation = { personal: 'other' };
    }
    return this.http
      .post(
        this.networksApi + `sidedrawer/sidedrawer-id/${sidedrawerId}/transfer`,
        dto,
        { headers: this.authService.getHeaders() }
      )
      .pipe(map(() => true));
  }

  forceUser(sidedrawerId: string): Observable<{ id?: string; _id?: string }> {
    return forkJoin([
      this.store.pipe(select(currentTenantSelector), take(1)),
      this.store.pipe(select(currentRoleSelector), take(1)),
      this.store.pipe(select(userHasBrandRoleSelector), take(1)),
      this.store.pipe(select(accountSelector), take(1)),
    ]).pipe(
      mergeMap(([tenant, role, isBrandRole, account]) => {
        const contributor: Contributor = {
          email: account.username,
          firstName: account.firstName,
          lastName: account.lastName,
          openId: account.openId,
        };
        if (isBrandRole) {
          contributor.brandCode = role.brandCode;
        }
        const body: Network = {
          sidedrawerRole: SidedrawerRoles.editor,
          contributor,
          relation: {
            profession: 'sponsor',
          },
        };
        return this.createSideDrawerNetworkV2FromTenantApi(
          tenant.id,
          sidedrawerId,
          SidedrawerRoles.editor,
          { openId: account.openId },
          {
            profession: 'sponsor',
          },
          tenant.region
        ).pipe(
          tap(response => {
            if (!!response?.id || !!response?._id) {
              const newNetwork: Network = {
                id: response?.id ? response?.id : response?._id,
                sidedrawer: sidedrawerId,
                contributor: { ...body.contributor, openId: account.openId },
                relation: body.relation,
                sidedrawerRole: SidedrawerRoles.editor,
              };
              this.store.dispatch(
                new SidedrawerNetworkLoaded({ network: newNetwork })
              );
            }
          })
        );
      })
    );
  }
}
