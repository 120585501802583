import { Component, inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserFlowStore } from './core/store/user-flow.store';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styles: [],
  standalone: false,
})
export class AppComponent implements OnInit {
  private readonly userFlowStore = inject(UserFlowStore);
  ngOnInit(): void {
    window.intercomSettings = {
      app_id: environment.intercomAppId,
      alignment: 'right',
      horizontal_padding: 20,
      vertical_padding: 20,
    };
    window.Intercom('boot', {
      app_id: environment.intercomAppId,
    });
    this.userFlowStore.initUserFlow();
  }
}
