import { DisplayValue } from 'src/app/record-types/models/display-value.model';

export function getValueDisplayValueHelper(
  displayValue: DisplayValue[],
  locale: string
): string {
  return (
    displayValue.find(displayVal => displayVal.locale === locale)?.value || ''
  );
}

export function updateValueDisplayValueHelper(
  newValue: string,
  locale: string,
  displayValue: DisplayValue[]
): DisplayValue[] {
  if (!displayValue) return [];
  return displayValue.map(dispValue =>
    dispValue.locale === locale
      ? {
          ...dispValue,
          value: newValue,
        }
      : dispValue
  );
}
