import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from '../../reducers';
import { environment } from '../../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SfAppComponent } from './sf-app.component';
import { SfAppRoutingModule } from './sf-app-routing.module';
import { SfCoreModule } from './core/sf-core.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [SfAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    environment.reduxToolsEnabled
      ? StoreDevtoolsModule.instrument({
          maxAge: environment.reduxMaxAge,
          logOnly: environment.production,
          serialize: true,
        })
      : [],
    SfAppRoutingModule,
    SfCoreModule,
    MatProgressBarModule,
  ],
  providers: [],
  bootstrap: [SfAppComponent],
})
export class SfAppModule {}
