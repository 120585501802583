import { PlanItemCustomOptionTypeDto } from 'src/app/plans/models/plan-item-custom-option-type.dto';
import { PlanItemType } from 'src/app/plans/models/plan-item-type.model';
import { PlanItem } from 'src/app/plans/models/plan-item.model';
import { getValidationTypeFromSpecificField } from 'src/app/plans/views/plan-items/models/validation-type.model';
import { DisplayValue } from 'src/app/record-types/models/display-value.model';
import { SpecificFieldType } from 'src/app/record-types/models/specific-field-type.model';

type GenerateNewPlanItemFieldProps = {
  id?: string;
  name: string;
  info: string | DisplayValue[];
  locales: string[];
  fieldValue?: string | number;
  validationFormType: SpecificFieldType;
  planItemType: PlanItemType;
  orderId: number;
  optional: boolean;
  tooltip?: DisplayValue[];
  options?: string[];
  takenFromPdf?: boolean;
};

export function generateNewPlanItemField({
  id,
  name,
  info,
  locales,
  fieldValue,
  validationFormType,
  planItemType,
  orderId,
  optional,
  tooltip,
  options,
  takenFromPdf,
}: GenerateNewPlanItemFieldProps): PlanItem {
  if (
    (validationFormType === SpecificFieldType.enum ||
      validationFormType === SpecificFieldType.optiongroup ||
      validationFormType === SpecificFieldType.radiogroup) &&
    (!options || options.length === 0)
  ) {
    // Generate default options
    options = ['Option 1', 'Option 2'];
  }
  return {
    id: id || crypto.randomUUID(),
    name,
    optional,
    orderId,
    itemType: planItemType,
    fieldValue,
    info:
      typeof info === 'string'
        ? getHardcodedMultiLanguageValue(locales, info)
        : info,
    validation: {
      type: getValidationTypeFromSpecificField(validationFormType),
      constraints: [],
      formType: {
        type: validationFormType,
        tooltip: tooltip || undefined,
        takenFromPdf,
        hidden: validationFormType === SpecificFieldType.signature,
        options:
          options && options.length > 0
            ? options.map(option =>
                getHardcodedOptionMultiLanguageValue(locales, option)
              )
            : undefined,
        validators:
          validationFormType === SpecificFieldType.email
            ? { email: true }
            : undefined,
        dateFormat:
          validationFormType === SpecificFieldType.date
            ? getHardcodedMultiLanguageValue(locales, '')
            : undefined,
        dateConfiguration:
          validationFormType === SpecificFieldType.date
            ? {
                pastAndFutureDate: 'both',
              }
            : undefined,
      },
    },
  } as PlanItem;
}

type GenerateNewPlanItemProps = {
  name: string;
  info: string;
  locales: string[];
  fieldValue?: string | number;
  planItemType: PlanItemType;
  orderId: number;
  optional?: boolean;
  takenFromPdf?: boolean;
};

export function generateNewPlanItem({
  name,
  info,
  locales,
  fieldValue,
  planItemType,
  orderId,
  optional,
  takenFromPdf,
}: GenerateNewPlanItemProps): PlanItem {
  return {
    id: crypto.randomUUID(),
    name: name,
    optional,
    orderId,
    fieldValue,
    itemType: planItemType,
    info: getHardcodedMultiLanguageValue(locales, info),
    validation: {
      type: getValidationTypeFromSpecificField(SpecificFieldType.string),
      constraints: [],
      formType: {
        type: SpecificFieldType.string,
        takenFromPdf,
      },
    },
  } as PlanItem;
}

type GenerateNewPlanItemRecordProps = {
  name: string;
  info: string;
  locales: string[];
  fieldValue?: string | number;
  orderId: number;
  optional?: boolean;
};

export function generateNewPlanItemRecord({
  name,
  info,
  locales,
  fieldValue,
  orderId,
  optional,
}: GenerateNewPlanItemRecordProps): PlanItem {
  return {
    id: crypto.randomUUID(),
    name: name,
    optional,
    orderId,
    fieldValue,
    itemType: PlanItemType.record,
    info: getHardcodedMultiLanguageValue(locales, info),
    records: [],
    recordType: {},
    recordSubtype: {},
    recordSubtypeOtherName: name,
  } as PlanItem;
}

export function getHardcodedMultiLanguageValue(
  locales: string[],
  value: string,
  description?: string
): DisplayValue[] {
  return locales.map(locale => {
    return new DisplayValue(locale, value || '', description || '');
  });
}

export function getHardcodedOptionMultiLanguageValue(
  locales: string[],
  value: string
): PlanItemCustomOptionTypeDto {
  return {
    value: value,
    key: crypto.randomUUID(),
    languages: locales.map(locale => {
      return new DisplayValue(locale, value);
    }),
    allowCustomAnswer: false,
    mustComplete: false,
  } as PlanItemCustomOptionTypeDto;
}
