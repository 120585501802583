import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/services/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { environment } from '../../../environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { Plan } from '../models/plan.model';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { NewPlanDto } from '../models/new-plan.dto';
import { PlanItem } from '../models/plan-item.model';
import { PlanItemsLoaded, PlanItemUpdated } from '../store/plan-items.actions';
import { PlanRequest } from '../models/plan-request.model';
import { Update } from '@ngrx/entity';
import { NewPlanItemDto } from '../views/plan-items/models/new-plan-item.dto';
import { SideDrawerPlanDto } from '../models/side-drawer-plan.dto';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import {
  currentRoleSelector,
  currentTenantSelector,
  userHasBrandRoleSelector,
} from '../../tenants/store/tenant.selectors';
import { PlanUpdated } from '../store/plan-list.actions';

@Injectable()
export class PlansService {
  private tenantApi = environment.tenantApi;
  private plansApi = environment.plansApi;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly store: Store<AppState>
  ) {}

  getPlansByBrandCode(): Observable<Plan[]> {
    return forkJoin([
      this.store.pipe(select(currentTenantSelector), take(1)),
      this.store.pipe(select(currentRoleSelector), take(1)),
      this.store.pipe(select(userHasBrandRoleSelector), take(1)),
    ]).pipe(
      mergeMap(([tenant, role, userHasBrandRole]) => {
        const operation = userHasBrandRole
          ? this.getPlans(tenant.id, role.brandCode)
          : this.getPlans(tenant.id);
        return operation.pipe(catchError(() => of([])));
      })
    );
  }

  getPlans(tenantId: string, brandCode?: string): Observable<Plan[]> {
    return this.http.get<Plan[]>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/plans${
          brandCode ? '?brandCode=' + brandCode : ''
        }`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  updatePlan(tenantId: string, plan: Plan): Observable<Plan | any> {
    return this.http.put<Plan>(
      this.tenantApi + `tenant/tenant-id/${tenantId}/plans/plan-id/${plan.id}`,
      { ...plan },
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  createPlan(tenantId: string, plan: NewPlanDto): Observable<{ _id: string }> {
    return this.http.post<{ _id: string }>(
      this.tenantApi + `tenant/tenant-id/${tenantId}/plans`,
      { ...plan },
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  deletePlan(tenantId: string, plan: Plan): Observable<boolean> {
    return this.http.delete<boolean>(
      this.tenantApi + `tenant/tenant-id/${tenantId}/plans/plan-id/${plan.id}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getPlanItems(tenantId: string, planId: string): Observable<PlanItem[]> {
    return this.http
      .get<PlanItem[]>(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/plans/plan-id/${planId}/items`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        tap(items => {
          this.store.dispatch(new PlanItemsLoaded({ items }));
        })
      );
  }

  getPlanItemsFormBuilder(
    tenantId: string,
    planId: string
  ): Observable<PlanItem[]> {
    return this.http.get<PlanItem[]>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/plans/plan-id/${planId}/items`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  updatePlanItem(
    tenantId: string,
    planId: string,
    itemId: string,
    orderId: number,
    optional: boolean
  ): Observable<Plan> {
    return this.http.put<Plan>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/plans/plan-id/${planId}/items/item-id/${itemId}`,
      { orderId, optional },
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  createPlanItem(
    tenantId: string,
    planId: string,
    item: NewPlanItemDto
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/plans/plan-id/${planId}/items`,
      { ...item },
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  deletePlanItem(
    tenantId: string,
    planId: string,
    itemId: string
  ): Observable<boolean> {
    return this.http.delete<boolean>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/plans/plan-id/${planId}/items/item-id/${itemId}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getPlanRequestedTo(tenantId: string, plan: Plan): Observable<PlanRequest[]> {
    return this.http
      .get<PlanRequest[]>(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/plans/plan-id/${plan.id}/sidedrawers`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        tap(plansRequested => {
          const changes: Plan = { ...plan, requestedTo: [...plansRequested] };
          const update: Update<Plan> = { id: plan.id, changes };
          this.store.dispatch(new PlanUpdated({ plan: update }));
        })
      );
  }

  getPlanRequestedToFormBuilder(
    tenantId: string,
    planId: string
  ): Observable<string[]> {
    return this.http
      .get<PlanRequest[]>(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/plans/plan-id/${planId}/sidedrawers`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        map(plansRequested => plansRequested.map(planR => planR.sidedrawerId))
      );
  }

  requestPlanToSideDrawer(
    sidedrawerId: string,
    planId: string
  ): Observable<boolean> {
    return this.http
      .post(
        this.plansApi +
          `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests`,
        { planId },
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(map(() => true));
  }

  removePlanRequestedFromSideDrawer(
    sidedrawerId: string,
    planId: string
  ): Observable<boolean> {
    return this.http
      .delete(
        this.plansApi +
          `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests/plan-request-id/${planId}`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(map(() => true));
  }

  getSidedrawerPlans(
    tenantId: string,
    sidedrawerId: string
  ): Observable<SideDrawerPlanDto[]> {
    return this.http.get<SideDrawerPlanDto[]>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/sidedrawers/sidedrawer-id/${sidedrawerId}/plan-requests`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getPLanRequest(tenantId: string, planId: string): Observable<Plan> {
    return this.http
      .get<Plan>(
        this.tenantApi + `tenant/tenant-id/${tenantId}/plans/plan-id/${planId}`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        map(plan => {
          return { ...plan, id: plan['_id'] || plan['id'] };
        })
      );
  }

  updatePlanListOrderIds(plans: Plan[]): Observable<any> {
    if (!UtilsHelper.checkListWithOrderIdIsInvalid<Plan>(plans)) {
      return of([]);
    }
    const operations = {};
    const plansToUpdate =
      UtilsHelper.replaceOrderIdsOfListWithOrderIds<Plan>(plans);
    plansToUpdate.forEach(plan => {
      operations[`${plan.id}`] = this.updatePlan(plan.tenant, plan).pipe(
        tap(() => {
          this.store.dispatch(
            new PlanUpdated({ plan: { id: plan.id, changes: plan } })
          );
        }),
        catchError(() => of(plan))
      );
    });
    return forkJoin(Object.values(operations));
  }

  updatePlanItemsOrderIds(
    tenantId: string,
    planId: string,
    items: PlanItem[]
  ): Observable<any> {
    if (!UtilsHelper.checkListWithOrderIdIsInvalid<PlanItem>(items)) {
      return of([]);
    }
    const operations = {};
    const itemsToUpdate =
      UtilsHelper.replaceOrderIdsOfListWithOrderIds<PlanItem>(items);
    itemsToUpdate.forEach(item => {
      operations[`${item.id}`] = this.updatePlanItem(
        tenantId,
        planId,
        item.id,
        item.orderId,
        item.optional
      ).pipe(
        tap(() => {
          this.store.dispatch(
            new PlanItemUpdated({ item: { id: item.id, changes: item } })
          );
        }),
        catchError(() => of(item))
      );
    });
    return forkJoin(Object.values(operations));
  }
}
