export class PlanItemFormType {
  constructor(
    public description?: string,
    public icon?: string,
    public instructions?: string,
    public title?: string,
    public type?: string,
    public nestable?: boolean,
    public instructionsb?: string,
  ) {}
}
