import { SpecificFieldType } from '../../../../record-types/models/specific-field-type.model';
import { PlanItemFormTypeDto } from '../../../models/plan-item-form-type.dto';

export enum ValidationType {
  IsString = 'isString',
  IsNumber = 'isNumber',
  IsEmail = 'IsEmail',
  IsOptional = 'IsOptional',
  IsInt = 'isInt',
  IsDecimal = 'isDecimal',
  IsUrl = 'isURL',
  IsDate = 'isDate',
  isCurrency = 'isCurrency',
  isArray = 'isArray',
}

export const getValidationTypeFromSpecificField = (
  field: SpecificFieldType | PlanItemFormTypeDto
): ValidationType => {
  if (typeof field === 'object') {
    switch (field.type) {
      case SpecificFieldType.url:
        return ValidationType.IsUrl;
      case SpecificFieldType.number:
      case SpecificFieldType.money:
      case SpecificFieldType.percentage:
        return ValidationType.IsNumber;
      case SpecificFieldType.integer:
        return ValidationType.IsDecimal;
      case SpecificFieldType.date:
        return ValidationType.IsDate;
      case SpecificFieldType.enum:
      case SpecificFieldType.email:
      case SpecificFieldType.address:
      case SpecificFieldType.rtf:
      case SpecificFieldType.string:
      default:
        return ValidationType.IsString;
    }
  }
  switch (field) {
    case SpecificFieldType.url:
      return ValidationType.IsUrl;
    case SpecificFieldType.number:
    case SpecificFieldType.money:
    case SpecificFieldType.percentage:
      return ValidationType.IsNumber;
    case SpecificFieldType.integer:
      return ValidationType.IsDecimal;
    case SpecificFieldType.date:
      return ValidationType.IsDate;
    case SpecificFieldType.enum:
    case SpecificFieldType.email:
    case SpecificFieldType.address:
    case SpecificFieldType.rtf:
    case SpecificFieldType.string:
    default:
      return ValidationType.IsString;
  }
};
