import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/services/auth.service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { SideDrawerTypesLoaded } from '../store/side-drawer-types.actions';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { SideDrawersLoaded } from '../store/sidedrawer-list.actions';
import { SideDrawer } from '../models/side-drawer.model';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { User } from '../../users/models/user.model';
import { brandsListSelector } from '../../branding/store/brand-list.selectors';
import { currentTenantSelector } from '../../tenants/store/tenant.selectors';
import { SideDrawerType } from '../models/side-drawer-type.model';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { UserUpdated } from '../../users/store/users-list.actions';
import { PaginatorApiResponse } from '../models/paginator-api-response.model';
import { CreateSideDrawerDto } from '../models/create-side-drawer.dto';
import { SideDrawerSearchFilter } from '../models/side-drawer-search.filter';
import { PaginatorHelper } from '../../core/helpers/paginator.helper';

@Injectable()
export class SideDrawersService {
  private tenantApi = environment.tenantApi;
  private recordsApi = environment.recordsApi;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly store: Store<AppState>
  ) {}

  getSideDrawerTypeByBrandCode(): Observable<SideDrawerTypesLoaded> {
    return forkJoin([
      this.store.pipe(select(currentTenantSelector), take(1)),
      this.store.pipe(select(brandsListSelector), take(1)),
    ]).pipe(
      mergeMap(([tenant, brands]) => {
        const operations = {};
        brands.forEach(brand => {
          operations[`${brand.brandCode}`] = this.getSideDrawerTypes(
            tenant.id,
            brand.brandCode
          ).pipe(
            map(response =>
              response.map(res => {
                return { name: res, brand };
              })
            ),
            catchError(() => of([]))
          );
        });
        return forkJoin(Object.values(operations)).pipe(
          map(responses => {
            let sdTypes: SideDrawerType[] = [];
            Object.values(responses).forEach((response: SideDrawerType[]) => {
              sdTypes = [...sdTypes, ...response];
            });
            sdTypes.sort((a, b) => UtilsHelper.compareStrings(a.name, b.name));
            return sdTypes.map((sdType, i) => {
              return {
                ...sdType,
                id: i,
                slug: (sdType.name + sdType.brand.brandCode)
                  .toLowerCase()
                  .trim()
                  .replace(/\s/g, ''),
              };
            });
          }),
          map(sdTypes =>
            sdTypes.filter(sdType => sdType?.name?.trim()?.length > 0)
          ),
          map(sdTypes => new SideDrawerTypesLoaded({ types: sdTypes }))
        );
      })
    );
  }

  getSideDrawerTypes(
    tenantId: string,
    brandCode?: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/records-type/sidedrawer-types${
          brandCode ? '?brandCode=' + brandCode : ''
        }`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getUserSideDrawers(tenantId: string, user: User): Observable<SideDrawer[]> {
    return this.http
      .get<SideDrawer[]>(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/users/open-id/${user.openId}/sidedrawers`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        catchError(() => {
          return throwError([]);
        }),
        tap(sidedrawers => {
          sidedrawers = sidedrawers?.length > 0 ? sidedrawers : [];
          const changes: User = { ...user, sidedrawers };
          const update: Update<User> = { id: user.username, changes };
          this.store.dispatch(new UserUpdated({ user: update }));
          this.store.dispatch(new SideDrawersLoaded({ sidedrawers }));
        })
      );
  }

  applyTemplate(
    sidedrawerId: string,
    templateId: string,
    copyRecordsName: boolean,
    copyPlanRequests?: boolean,
    copySidedrawerCollaborators?: boolean,
    region?: string
  ): Observable<boolean> {
    return this.http
      .put(
        this.recordsApi + `sidedrawer/sidedrawer-id/${sidedrawerId}/template`,
        {
          sidedrawerTemplateId: templateId,
          copyRecordsName,
          copyPlanRequests,
          copySidedrawerCollaborators,
          region,
        },
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(map(() => true));
  }

  updateSidedrawer(sidedrawerId: string, body): Observable<boolean> {
    if (body.emailUsername) {
      delete body.emailUsername;
    }
    return this.http
      .put(this.recordsApi + `sidedrawer/sidedrawer-id/${sidedrawerId}`, body, {
        headers: this.authService.getHeaders(),
      })
      .pipe(map(() => true));
  }

  deleteSidedrawer(tenantId: string, sidedrawerId: string): Observable<string> {
    return this.http.delete<string>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/sidedrawers/sidedrawer-id/${sidedrawerId}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getTenantSideDrawersByBrandCode(
    tenantId: string,
    brandCode: string,
    region: string,
    startingAfter?: string,
    limit?: number,
    filter?: SideDrawerSearchFilter
  ): Observable<PaginatorApiResponse<SideDrawer>> {
    let url =
      UtilsHelper.changeVersionApiUrl(this.tenantApi, 2) +
      `tenant/tenant-id/${tenantId}/sidedrawers?brandCode=${brandCode}&isTemplate=false`;
    if (startingAfter) {
      url += `&startingAfter=${PaginatorHelper.cleanStartingAfterQueryParam(
        startingAfter
      )}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (filter) {
      Object.keys(filter).forEach(key => {
        // TODO REMOVE THIS WHEN THE API FIXES
        if (filter?.type) {
          return;
        }
        if (filter[key]) {
          url += `&${key}=${filter[key]}`;
        }
      });
    }
    url += `&region=${region}`;
    return this.http.get<PaginatorApiResponse<SideDrawer>>(url, {
      headers: this.authService.getHeaders(),
    });
  }

  getPagingTenantSideDrawers(
    tenantId: string,
    brandCode: string,
    region: string,
    startingAfter?: string,
    limit?: number,
    filter?: SideDrawerSearchFilter
  ): Observable<PaginatorApiResponse<SideDrawer>> {
    let url =
      UtilsHelper.changeVersionApiUrl(this.tenantApi, 2) +
      `tenant/tenant-id/${tenantId}/sidedrawers?isTemplate=false`;
    if (startingAfter) {
      url += `&startingAfter=${PaginatorHelper.cleanStartingAfterQueryParam(
        startingAfter
      )}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key] !== undefined) {
          url += `&${key}=${filter[key]}`;
        }
      });
    }
    url += `&region=${region}`;
    return this.http.get<PaginatorApiResponse<SideDrawer>>(url, {
      headers: this.authService.getHeaders(),
    });
  }

  switchSideDrawerBrand(
    tenantId: string,
    sidedrawerId: string,
    brandCode: string
  ): Observable<boolean> {
    return this.http
      .put(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/sidedrawers/sidedrawer-id/${sidedrawerId}`,
        { brandCode },
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(map(() => true));
  }

  createSideDrawer(dto: CreateSideDrawerDto): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(this.recordsApi + 'sidedrawer', dto, {
      headers: this.authService.getHeaders(),
    });
  }
}
