import { Dictionary } from '../../dictionary/models/dictionary.model';
import { Locale } from '../../dictionary/models/locale.model';
import * as moment from 'moment';

export class DateHelper {
  static formatColumnDate(dateString: string, locale: Locale): string {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    moment.locale(Locale.getLocaleId(locale));
    return moment(date).format('LL');
  }

  static formatDate(
    dateString: string,
    dictionary: Dictionary,
    locale: Locale
  ): string {
    const now = new Date();
    const date = new Date(dateString);
    const timeDifference = now.getTime() - date.getTime();
    const dayInMilliseconds = 86400000;
    const daysDifference = timeDifference / dayInMilliseconds;
    if (daysDifference < 1 && now.getDay() === date.getDay()) {
      return moment(date).format('hh:mm a');
    } else if (
      (daysDifference < 1 && now.getDay() !== date.getDay()) ||
      daysDifference === 1
    ) {
      return dictionary?.globalparams_yesterday;
    } else if (daysDifference > 1 && daysDifference <= 7) {
      return this.getWeekDay(date, dictionary);
    } else {
      moment.locale(Locale.getLocaleId(locale));
      return moment(date).format('LL');
    }
  }

  static getWeekDay(date: Date, dictionary: Dictionary): string {
    switch (date.getDay()) {
      case 1:
        return dictionary?.globalparams_monday;
      case 2:
        return dictionary?.globalparams_tuesday;
      case 3:
        return dictionary?.globalparams_wednesday;
      case 4:
        return dictionary?.globalparams_thursday;
      case 5:
        return dictionary?.globalparams_friday;
      case 6:
        return dictionary?.globalparams_saturday;
      case 7:
        return dictionary?.globalparams_sunday;
      default:
        return '';
    }
  }

  static happensToday(dateString: string): boolean {
    const now = new Date();
    const date = new Date(dateString);
    const timeDifference = now.getTime() - date.getTime();
    const dayInMilliseconds = 86400000;
    const daysDifference = timeDifference / dayInMilliseconds;
    return daysDifference < 1 && now.getDay() === date.getDay();
  }

  static compareDates(dateStringA: Date, dateStringB: Date): number {
    const dateA = new Date(dateStringA);
    const dateB = new Date(dateStringB);
    return dateA.getTime() - dateB.getTime();
  }

  static getCorrelationId(): string {
    return moment().format('YY MM DD HH mm ss').split(' ').join('');
  }

  static getTodayQuery(): string {
    const now = new Date();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const date = now.getDate();
    return `?today=${year * 10000 + month * 100 + date}`;
  }
}
