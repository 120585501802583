import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdFlatButtonComponent } from './components/sd-flat-button/sd-flat-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';
import { SdIconButtonComponent } from './components/sd-icon-button/sd-icon-button.component';
import { AlertButtonComponent } from './components/alert-button/alert-button.component';
import { SdTooltipButtonComponent } from './components/sd-tooltip-button/sd-tooltip-button.component';
import { SdSvgComponent } from './components/sd-svg/sd-svg.component';
import { SdSvgButtonComponent } from './components/sd-svg-button/sd-svg-button.component';
import { SdSelectedItemComponent } from './components/sd-selected-item/sd-selected-item.component';
import { SdSelectableItemComponent } from './components/sd-selectable-item/sd-selectable-item.component';
import { SdUploadButtonComponent } from './components/sd-upload-button/sd-upload-button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SdCheckButtonComponent } from './components/sd-check-button/sd-check-button.component';
import { SdCacheModule } from '../sd-cache/sd-cache.module';
import { SdFabSideComponent } from './components/sd-fab-side/sd-fab-side.component';
import { SdFabExtendedComponent } from './components/sd-fab-extended/sd-fab-extended.component';

@NgModule({
  declarations: [
    SdFlatButtonComponent,
    SdIconButtonComponent,
    AlertButtonComponent,
    SdTooltipButtonComponent,
    SdSvgComponent,
    SdSvgButtonComponent,
    SdSelectedItemComponent,
    SdSelectableItemComponent,
    SdUploadButtonComponent,
    SdCheckButtonComponent,
    SdFabSideComponent,
    SdFabExtendedComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatIconModule,
    ReactiveFormsModule,
    SdCacheModule,
  ],
  exports: [
    SdFlatButtonComponent,
    SdIconButtonComponent,
    AlertButtonComponent,
    SdTooltipButtonComponent,
    SdSvgComponent,
    SdSvgButtonComponent,
    SdSelectedItemComponent,
    SdSelectableItemComponent,
    SdUploadButtonComponent,
    SdCheckButtonComponent,
    SdFabSideComponent,
    SdFabExtendedComponent,
  ],
})
export class SdButtonsModule {}
